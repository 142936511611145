<template>
  <div class="v-upload">
    <van-uploader
      v-model="fileList"
      :multiple="multiple"
      :max-count="maxCount"
      :accept="type"
      :upload-icon="addImg"
      :after-read="afterRead"
      :before-delete="beforeDelete"
      :before-read="beforeRead"
      :disabled="disabled"
      :deletable="deletable"
      :show-upload="showUpload"
    />
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import { Uploader } from "vant";
import addImg from "./images/add-photo.png";
Vue.use(Uploader);
export default {
  watch: {
    imgUrl(newValue) {
      this.uploadList = [];
      console.log("变化");
      if (typeof newValue !== "string" && this.fileList.length === 0) {
        newValue.forEach((url) => {
          this.fileList.push({ url: url, isImage: true });
        });
      } else {
        if (this.maxCount == 1) {
          let img = newValue ? [{ url: newValue }] : [];
          this.fileList = img;
          this.uploadList = Object.assign(this.fileList);
        } else {
          this.fileList = [];
          if (newValue) {
            let arr = newValue.split(",");
            arr.forEach((ele) => {
              this.fileList.push({ url: ele });
            });
            this.uploadList = Object.assign(this.fileList);
          }
        }
      }
    },
  },
  components: {},
  props: {
    // 图片url队列 与fileList二选一 不可同时存在 同时存在的请客fileLis无效
    imgUrls: {
      type: [Array, String],
    },
    uploadUrl: {
      type: String,
      default: "/gateway/blade-resource/oss/endpoint/put-file",
    },
    imgUrl: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "image/*",
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    // 是否展示上传区域
    showUpload: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      addImg,
      fileList: [],
      uploadList: [],
      status: 2,
    };
  },
  created() {
    let newValue = this.imgUrl;
    console.log("初始化");
    this.uploadList = [];
    if (this.maxCount == 1) {
      let img = newValue ? [{ url: newValue }] : [];
      this.fileList = img;
      this.uploadList = Object.assign(this.fileList);
    } else {
      this.fileList = [];
      if (newValue) {
        let arr = newValue.split(",");
        arr.forEach((ele) => {
          this.fileList.push({ url: ele });
        });
        this.uploadList = Object.assign(this.fileList);
      }
    }
  },
  methods: {
    beforeRead() {
      console.log(234, 90);
      if (this.status == 3) {
        this.$toast("上一张正在上传中，请等待");
      } else {
        return true;
      }
    },
    beforeDelete(file, detail, ad) {
      let _this = this;
      console.log(file, detail, "删除", ad);
      let arr = _.cloneDeep(_this.uploadList);
      arr.splice(detail.index, 1);
      _this.uploadList = arr;
      _this.init();
      return true;
    },
    afterRead(file, detail) {
      console.log(file, detail);
      var formData = new FormData();
      if (file.file) {
        formData.append("file", file.file);
      }
      file.status = "uploading";
      file.message = "上传中...";
      this.status = 3;
      this.$axios.post(`${this.uploadUrl}`, formData).then((res) => {
        this.status = 1;
        console.log(res, 998);
        if (res.code == 200) {
          if (res.data.link) {
            this.uploadList.push({ url: res.data.link, index: detail.index });
            this.updateFile(this.uploadList);
            this.init();
          }
          file.status = "done";
          file.message = "上传成功";
          this.callbcak();
        } else {
          file.status = "failed";
          file.message = "上传失败";
          this.$toast(res.msg);
          this.callbcak();
        }
        console.log(this.uploadList, 2399888);
      });
    },
    updateFile(val) {
      if (this.limit == 1) {
        let urlArr = val.map((item) => item.url);
        if (val.length > 0) {
          // @arg imgUrls 图片列表 双向绑定 limit为1时String，>1时Array
          val[0].url && this.$emit("update:activityPicture", val[0].url);
        } else {
          this.$emit("update:activityPicture", "");
        }
      } else {
        let urlArr = [];
        val.forEach((item) => {
          urlArr.push(item.url);
        });
        let urlStr = urlArr.join(",");
        this.$emit("update:activityPicture", urlStr);
      }
    },
    init() {
      let arr = [];
      let toArr = [];
      Object.assign(toArr, this.uploadList);
      toArr.forEach((ele) => {
        arr.push(ele.url);
      });
      let urlLink = arr.join(",");
      this.$emit("update:activityPicture", urlLink);
    },
    callbcak() {
      this.$emit("uploadAfter");
    },
  },
};
</script>

<style lang="less">
.v-upload {
  .van-uploader__preview {
    border: 2px dashed #eee;
  }
  .van-uploader__preview-image {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
  }
  .van-uploader__upload {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
  }
  .van-uploader__upload {
    margin: 0;
  }
}
</style>
