<template>
  <div id="app">
    <keep-alive>
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { getHashParam } from "@/utils/utils.js";
export default {
  name: "areaAppoint",
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.toHandleFontSize();
  },
  methods: {
    toHandleFontSize() {
      if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
        // eslint-disable-next-line no-inner-declarations
        function handleFontSize() {
          // eslint-disable-next-line no-undef
          const bridge = window.WeixinJSBridge;
          bridge.invoke("setFontSizeCallback", { fontSize: 2 });
          bridge.on("menu:setfont", function () {
            bridge.invoke("setFontSizeCallback", { fontSize: 2 });
          });
        }
        // eslint-disable-next-line no-undef
        if (
          typeof window.WeixinJSBridge == "object" &&
          typeof window.WeixinJSBridge.invoke == "function"
        ) {
          handleFontSize();
        } else {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              handleFontSize,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", handleFontSize);
            document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
          }
        }
      }
    },
  },
};
</script>
<style lang="less">
#app {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
@import url("./assets/less/reset.less");
@import url("./assets/less/common.less");
</style>
