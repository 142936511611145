<template>
  <div class="v-sign">
    <div class="wrapper">
      <div class="handBtn">
        <button @click="retDraw" class="delBtn">重写</button>
        <!-- <button @click="clip" class="saveBtn">裁剪</button> -->
        <!-- <button @click="saveCanvasAsImg" class="saveBtn">保存</button>
        <button @click="previewCanvasImg" class="previewBtn">预览</button>
        <button @click="uploadCanvasImg" class="uploadBtn">上传</button> -->
        <button @click="subCanvas" class="subBtn">完成</button>
      </div>
      <div class="handCenter">
        <canvas
          ref="canvas"
          class="handWriting"
          @touchstart="uploadScaleStart"
          @touchmove="uploadScaleMove"
          @touchend="uploadScaleEnd"
        ></canvas>
      </div>
      <div class="handRight">
        <div class="handTitle">请签名</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    uploadUrl: {
      type: String,
      default: "/gateway/blade-resource/oss/endpoint/put-file",
    },
    lineWidth: {
      type: Number,
      default: 4,
    },
    lineColor: {
      type: String,
      default: "#000",
    },
    isCrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canvasRect: null, // 宽高clientRect数据
      ctx: null, // 画笔对象
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      storageSteps: [], // 记录每步操作
      isDrawing: false, // 是否正在绘制
      isEmpty: true, // 画板是否为空
    };
  },
  mounted() {
    this.init();
    document.onmouseup = () => {
      this.isDrawing = false;
    };
    // const canvas = document.getElementById(this.canvasName);
    // this.ctx = canvas.getContext("2d");
  },
  methods: {
    init() {
      const canvas = this.$refs.canvas;
      this.canvasRect = canvas.getBoundingClientRect();
      console.log(this.canvasRect);
      canvas.width = this.canvasRect.width;
      canvas.height = this.canvasRect.height;
      this.ctx = canvas.getContext("2d");
    },
    // 笔迹开始
    uploadScaleStart(e) {
      e.preventDefault();
      this.startX = e.targetTouches[0].clientX - this.canvasRect.left;
      this.startY = e.targetTouches[0].clientY - this.canvasRect.top;
      this.endX = this.startX;
      this.endY = this.startY;
      this.draw();
    },
    // 笔迹移动
    uploadScaleMove(e) {
      e.preventDefault();

      this.endX = e.targetTouches[0].clientX - this.canvasRect.left;
      this.endY = e.targetTouches[0].clientY - this.canvasRect.top;
      this.draw();
      this.startX = this.endX;
      this.startY = this.endY;
    },
    // 笔迹结束
    uploadScaleEnd(e) {
      e.preventDefault();
      // console.log(e)
      this.endX = e.changedTouches[0].clientX - this.canvasRect.left;
      this.endY = e.changedTouches[0].clientY - this.canvasRect.top;

      let imgData = this.ctx.getImageData(
        0,
        0,
        this.canvasRect.width,
        this.canvasRect.height
      );
      console.log(imgData);
      this.storageSteps.push(imgData);
    },
    draw() {
      this.ctx.beginPath();
      this.ctx.moveTo(this.startX, this.startY);
      this.ctx.lineTo(this.endX, this.endY);
      this.ctx.lineCap = "round";
      this.ctx.lineJoin = "round";
      this.ctx.lineWidth = this.lineWidth;
      this.ctx.strokeStyle = this.lineColor;
      this.ctx.stroke();
      this.ctx.closePath();

      this.isEmpty = false;
    },
    retDraw() {
      this.ctx.clearRect(0, 0, this.canvasRect.width, this.canvasRect.height);
      this.storageSteps = []; // 清空清楚步骤记录
      this.isEmpty = true; // 清空标记
    },
    //将Canvas内容转成 临时图片 --> cb 为回调函数 形参 tempImgPath 为 生成的图片临时路径
    canvasToImg() {},
    //完成
    subCanvas() {
      if (this.isEmpty) {
        console.log("画布为空！");
        return;
      }
      const base64 = this.$refs.canvas.toDataURL("image/png");
      console.log(base64);
      this.uploadImg(base64);
      // const name = prompt("请输入名称", "canvas签名");
      // if (name && name.trim()) {
      // // const a = document.createElement("a");
      // // a.download = "签名";
      // // a.href = this.$refs.canvas.toDataURL("image/png");
      // // // console.log(a)
      // // a.dispatchEvent(new MouseEvent("click")); // IE可能存在兼容性 可以把标签渲染出来再触发click事件
      // }
    },

    uploadImg(base64) {
      var _this = this;
      //构造formData对象
      var formData = new FormData();
      formData.append("file", base64);
      //调用上传文件接口
      this.$axios.post(this.uploadUrl, formData).then((res) => {
        console.log(res, "res---");
      });
    },
    // 裁剪
    clip() {
      if (this.isEmpty) {
        console.log("画布为空！");
        return;
      }
      const imgData = this.ctx.getImageData(
        0,
        0,
        this.canvasRect.width,
        this.canvasRect.height
      );
      const clipArea = this.getCropArea(imgData.data);
      console.log(clipArea);

      const _canvas = document.createElement("canvas");
      _canvas.width = clipArea.x2 - clipArea.x1;
      _canvas.height = clipArea.y2 - clipArea.y1;
      const _ctx = _canvas.getContext("2d");

      const imageData = this.ctx.getImageData(
        clipArea.x1,
        clipArea.y1,
        _canvas.width,
        _canvas.height
      );
      _ctx.putImageData(imageData, 0, 0);
      const base64 = _canvas.toDataURL("image/png");

      const img = document.querySelector(".preview img");
      img.src = base64;
      img.width = _canvas.width;
      img.height = _canvas.height;
    },
    // 计算空白区域
    getCropArea(imgData) {
      let x1 = Math.round(this.canvasRect.width);
      let y1 = Math.round(this.canvasRect.height);
      let x2 = 0;
      let y2 = 0;
      console.log([x1, y1, x2, y2]);

      for (let i = 0; i < Math.round(this.canvasRect.width); i++) {
        for (let j = 0; j < Math.round(this.canvasRect.height); j++) {
          let pos = (i + Math.round(this.canvasRect.width) * j) * 4;
          if (
            imgData[pos] > 0 ||
            imgData[pos + 1] > 0 ||
            imgData[pos + 2] ||
            imgData[pos + 3] > 0
          ) {
            // 判断第j行第i列的像素不是透明的
            // 找到有色彩的左上角坐标和右下角坐标
            x1 = Math.min(i, x1);
            y1 = Math.min(j, y1);
            x2 = Math.max(i, x2);
            y2 = Math.max(j, y2);
          }
        }
      }
      x1++;
      y1++;
      x2++;
      y2++;
      return { x1, y1, x2, y2 }; // 由于循环是从0开始的,而我们认为的行列是从1开始的
    },
    //保存到相册
    saveCanvasAsImg() {},
    //预览
    previewCanvasImg() {},
    //上传
    uploadCanvasImg() {},
  },
};
</script>

<style lang="less" scoped>
.v-sign {
  height: 100%;
  width: 100%;
  .wrapper {
    width: 100%;
    height: 100%;
    // margin: 30px 0;
    overflow: hidden;
    display: flex;
    align-content: center;
    // flex-direction: row;
    justify-content: center;
    font-size: 28px;
  }

  .handRight {
    display: inline-flex;
    align-items: center;
    .handTitle {
      transform: rotate(90deg);
      flex: 1;
      color: #666;
    }
  }

  .handCenter {
    border: 4px dashed #e9e9e9;
    flex: 5;
    overflow: hidden;
    box-sizing: border-box;
    .handWriting {
      // background: #fff;
      width: 100%;
      height: 100%;
    }
  }

  .handBtn {
    position: relative;
    height: 100%;
    // display: inline-flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-content: space-between;
    flex: 1;
    button {
      display: inline-flex;
      white-space: nowrap;
      padding: 12px 32px;
      font-size: 28px;
      color: #666;
      border: 2px solid #e9e9e9;
    }
    .delBtn {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }
    .saveBtn {
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }
    .previewBtn {
      position: absolute;
      top: 360px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      color: #666;
    }
    .uploadBtn {
      position: absolute;
      top: 520px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }
    .subBtn {
      position: absolute;
      bottom: 40px;
      left: 50%;
      display: inline-flex;
      transform: translateX(-50%) rotate(90deg);
      background: #008ef6;
      color: #fff;
      margin-bottom: 30px;
      text-align: center;
      justify-content: center;
    }
  }
}
</style>
