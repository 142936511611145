const routes = [
  //   健康小屋
  {
    path: "/futureHealth/healthHourse",
    name: "healthHourse",
    meta: {
      title: "健康小屋",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/healthHourse"], resolve),
  },
  //   运动打卡
  {
    path: "/futureHealth/health",
    name: "health",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/futureHealth/health"], resolve),
  },
  //   运动打卡列表
  {
    path: "/futureHealth/healthList",
    name: "healthList",
    meta: {
      title: " ",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/health/healthList.vue"], resolve),
  },
  //   运动打卡详情
  {
    path: "/futureHealth/healthDetail",
    name: "healthDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/health/healthDetail.vue"], resolve),
  },
  //   运动打卡审核列表
  {
    path: "/futureHealth/verifySport",
    name: "verifySport",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/verifySport/index.vue"], resolve),
  },
  //   运动打卡审核详情
  {
    path: "/futureHealth/verifySportDetail",
    name: "verifySportDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/verifySport/verifySportDetail.vue",
      ], resolve),
  },
  //精准防疫首页
  {
    path: "/futureHealth/accurateAntiepidemic",
    name: "accurateAntiepidemic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/accurateAntiepidemic/index.vue"], resolve),
  },
];

export default routes;
