<template>
  <div class="wrap">
    <div class="box1" v-if="isOpen == false">
      <span class="content">{{
        showContent.length > 20
          ? showContent.substring(0, 15) + "..."
          : showContent
      }}</span>
      <span
        class="btn"
        v-show="showContent.length > 20"
        @click.stop="isOpen = true"
        >[展开]</span
      >
    </div>
    <div class="box2" v-else>
      <span>{{ showContent }}</span>
      <span class="btn" @click.stop="isOpen = false">[收起]</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false, //是否展开
    };
  },
  props: {
    showContent: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);

  .box1 {
    display: flex;
    align-items: center;
    white-space: nowrap;
    line-height: 44px;

    .content {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .box2 {
    line-height: 44px;
    align-items: center;
  }

  .btn {
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 10px;
  }
}
</style>
