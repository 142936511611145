<template>
  <div class="commentList">
    <v-refresh v-model="isLoading" @refresh="onRefresh" class="main">
      <div class="comments">
        <div class="head-body">
          <div class="header">
            <span>评论</span
            ><span>（{{ commentsData.commentCount || "0" }}）</span>
          </div>
        </div>
        <div class="comments-empty" v-if="!commentsData.commentCount">
          暂无评论
        </div>
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="comments-main"
            v-for="(comment, index) in commentList"
            :key="index"
          >
            <div class="userPhoto">
              <img
                :src="
                  comment.avatar
                    ? comment.avatar
                    : require('./img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="userInfo">
              <div class="info-title">{{ comment.userName }}</div>
              <div class="info-content">
                {{ comment.commentContent }}
              </div>
              <div class="picList" v-if="comment.commentPic != ''">
                <div
                  v-for="(item, index) in comment.commentPic.split(',')"
                  :key="index"
                  @click="showImgClick(comment.commentPic.split(','), index)"
                >
                  <img style="width: 100%" :src="item" alt="" />
                </div>
              </div>

              <div
                class="info-reply"
                v-if="JSON.stringify(comment.latestReply) != '{}'"
              >
                <div class="info-reply__content">
                  <span class="content-user">{{
                    comment.latestReply.fromUserName
                  }}</span>
                  <span
                    class="content-to-user"
                    v-if="comment.latestReply.toUserName"
                  >
                    回复 @{{ comment.latestReply.toUserName }}</span
                  >
                  <span class="content-colon">：</span>
                  <span class="content-text">
                    {{ comment.latestReply.replyContent }}
                  </span>
                </div>
                <div
                  class="info-reply__btn"
                  v-show="comment.replyCount > 0"
                  @click="toRouteReply(comment)"
                >
                  <span>共 {{ comment.replyCount }} 条回复</span>
                  <img src="./img/icon-arrow.png" alt="" />
                  <!-- <i class="el-icon-arrow-right"></i> -->
                </div>
              </div>
              <div class="info-foot">
                <div class="foot-time">{{ comment.createTime }}</div>
                <div class="foot-wrap">
                  <div class="iconText" @click="toComments(comment)">
                    <div class="icon">
                      <img src="./img/icon-msg.png" />
                    </div>
                  </div>
                  <div class="iconText" @click="toClickLike(comment)">
                    <div class="icon icon-up">
                      <img
                        v-if="!comment.hasPraised"
                        src="./img/icon-like.png"
                      />
                      <img v-else src="./img/icon-like-red.png" alt="" />
                    </div>
                    <div class="text">
                      <span :class="{ hasPraised: comment.hasPraised }">{{
                        comment.praiseCount
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
    <v-picList
      :startPosition="startPosition"
      v-model="showImg"
      :imgList="imgList"
    >
    </v-picList>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils";
import { toRegister } from "@/utils/common";
import { origin } from "../../../../public/config/request.js";
import { cancelPraiseURL, addPraiseURL, getTopicCommentList } from "./api";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      isLoading: false,
      isStop: 1,
      isShowComments: false,
      curReply: null, // 用于记录回复的哪一条评论
      span: "",
      commentPic: "",
      commentsData: {},
      finished: false,
      finishedText: "",
      commentList: [],
      curPage: 1,
      pageSize: 10,
      id: "",
      startPosition: 0,
      showImg: false,
      imgList: [],
    };
  },
  props: {
    topicId: {
      type: String,
      required: true,
    },
    toRegisterParams: {
      type: Object,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return {};
      },
    },
    // 评论控件标题
    titlespan: {
      type: String,
      default: "评论",
    },
    // 是否可以评论
    isCanMsg: {
      type: Boolean,
      default: true,
    },
    // 是否可以点赞
    isCanLike: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.id = this.topicId;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    // 跳转回复页面
    toRouteReply(item) {
      this.$router.push({
        name: "replyDetail",
        query: {
          title: `${item.replyCount}条回复`,
          detail: JSON.stringify(item),
          latestReply: null, // 用于覆盖掉回复数据
        },
      });
    },
    toComments(value) {
      this.$emit("toComments", value);
    },
    // 点赞和取消点赞
    async toClickLike(data) {
      console.log(this.toRegisterParams, 9876);
      if (this.isStop == 1) {
        this.isStop = 0;
        if (
          await toRegister(
            this.toRegisterParams.url,
            this.toRegisterParams.params,
            this.toRegisterParams.name
          )
        ) {
        }
        const item = data || this.detail;
        const path = item.hasPraised ? cancelPraiseURL : addPraiseURL;
        let params = {
          targetId: data ? item.commentId : item.id, // commentId 评论主键id
          userId: this.userId,
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
        };
        this.$axios
          .post(`${path}`, this.$qs.stringify(params), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((res) => {
            this.isStop = 1;
            if (res.code === 200) {
              if (item.hasPraised) {
                // 取消点赞
                item.hasPraised = false;
                item.praiseCount -= 1;
              } else {
                // 点赞
                item.hasPraised = true;
                item.praiseCount += 1;
              }
              return;
            }
          })
          .catch((res) => {
            this.isStop = 1;
            this.$toast({
              message: res.msg || "操作失败，请稍后重试！",
              duration: 500,
            });
          });
      }
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicCommentList, {
          params: {
            topicId: this.id,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.commentList = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            this.commentsData = res.data;
            if (this.curPage === 1) {
              this.commentList = res.data.records;
              this.commentList.forEach((ele) => {
                if (ele.avatar) {
                  ele.avatar = handleImg(72, 72, ele.avatar);
                }
              });
            } else {
              const list = this.commentList.map((v) => v.commentId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.commentId) || this.commentList.push(item);
              });
            }
            this.curPage++;
          } else {
            this.finished = true;
          }
        })
        .catch((res) => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 判断是否是 空对象或者null
    isEmpty(val) {
      if (!val) {
        return false;
      } else {
        return Object.keys(val).length > 0;
      }
    },
    showImgClick(list, index) {
      this.showImg = true;
      this.imgList = list;
      this.startPosition = index;
      this.$emit("showImgClick", list, index);
    },
  },
};
</script>

<style lang="less">
.commentList {
  box-sizing: border-box;
  .main {
    box-sizing: border-box;
    padding: 0px 0px 128px 0px;
    // margin-top: 32px;
    .greyLine {
      width: 100%;
      height: 32px;
      position: relative;
      .greyLineBox {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f5f5f5;
      }
    }
    .content {
      width: 100%;
      overflow-x: hidden;
      box-sizing: border-box;
      margin-bottom: 80px;
      font-size: 32px;
      line-height: 1.5;
      color: #333333;
      word-wrap: break-word;
      padding: 0 32px;

      > .text-empty {
        height: 96px;
        line-height: 96px;
        background: #eeeeee;
        text-align: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      ::v-deep img {
        width: 100%;
      }
      ::v-deep video {
        width: 100%;
      }
    }

    .comments {
      .head-body {
        padding: 0 32px 0;
        box-shadow: 0px -2px 0px 0px #f0f0f0;
        border-bottom: 2px solid rgba(240, 240, 240, 1);
        .header {
          padding: 28px 0px;
          font-size: 28px;
          font-weight: 700;
          color: #1a1c34;
          line-height: 44px;

          > text {
            &:nth-of-type(1) {
              margin-right: 8px;
            }

            // &:nth-of-type(2) {
            // }
          }
        }
      }
      .comments-empty {
        display: flex;
        font-size: 28px;
        line-height: 44px;
        color: #999999;
        justify-content: center;
      }

      .comments-main {
        display: flex;
        margin-top: 32px;
        padding: 0 32px;
        box-sizing: border-box;

        .userPhoto {
          height: 72px;
          width: 72px;
          min-width: 72px;
          overflow: hidden;
          border-radius: 50%;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        .userInfo {
          word-wrap: break-word;
          flex: 1;
          margin-left: 16px;
          max-width: calc(100% - 88px);
          .picList {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            div {
              width: 30%;
              height: 200px;
              margin-bottom: 15px;
              margin-right: 15px;
              background-color: black;
              position: relative;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          .info-title {
            box-sizing: border-box;
            width: 100%;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .info-content {
            font-size: 32px;
            margin-top: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }

          .info-reply {
            background: #f7f7f7;
            line-height: 40px;
            padding: 24px;
            margin-top: 24px;
            word-wrap: break-word;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;

            .info-reply__content {
              .content-user,
              .content-colon {
                font-weight: bold;
                color: #333333;
              }

              .content-to-user {
                color: blur;
              }

              .content-text {
                font-weight: 400;
                color: #666666;
                vertical-align: middle;
              }
            }

            .info-reply__btn {
              margin-top: 8px;
              white-space: nowrap;
              font-weight: bold;
              color: #333333;
              display: flex;
              align-items: center;

              > span {
                font-weight: bold;
                color: #333333;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .info-foot {
            margin-top: 24px;
            line-height: 32px;
            display: flex;

            .foot-time {
              color: #a8a8a8;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a8a8a8;
            }

            .foot-wrap {
              flex: 1;
              display: flex;
              justify-content: flex-end;

              > .iconText {
                display: flex;
                // margin-right: 32px;
                color: #a8a8a8;
                align-items: center;

                &:not(:last-child) {
                  margin-right: 32px;
                }

                .icon {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;

                  &.icon-up {
                    > img {
                      margin-top: -2px;
                    }
                  }

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .text {
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  height: 32px;

                  span {
                    &.hasPraised {
                      color: #ff853a;
                    }

                    // color: #ff853a;
                    font-size: 24px;
                    line-height: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
