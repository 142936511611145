<template>
  <div>
    <van-radio-group
      v-model="radio"
      :disabled="disabled"
      :direction="direction"
      @change="change"
    >
      <div v-for="(item, index) in radioObj" :key="index">
        <van-radio :name="item.name">{{ item.value }}</van-radio>
      </div>
    </van-radio-group>
  </div>
</template>

<script>
import Vue from "vue";
import { Radio, RadioGroup } from "vant";
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  components: {},
  watch: {
    value(newVal) {
      this.radio = newVal;
    },
    // 监听value，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
    radio(newVal) {
      this.$emit("update:value", newVal);
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    radioObj: {
      type: Array,
      default: function () {
        return [{ name: "", value: "" }];
      },
    },
    value: {
      type: [String, Number],
      default: "1",
    },
    direction: {
      type: String,
      default: "horizontal",
    },
  },
  data() {
    return {
      radio: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
