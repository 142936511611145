<template>
  <div>
    <van-image-preview
      :startPosition="startPosition"
      @change="change"
      v-model="model"
      :images="imgList"
    >
    </van-image-preview>
  </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview } from "vant";
Vue.use(ImagePreview);
export default {
  watch: {
    value(newValue) {
      this.model = newValue;
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
  components: {},
  props: {
    startPosition: {
      type: [Number, String],
      default: 0,
    },
    value: {
      type: Boolean,
      default: false,
    },
    imgList: {
      type: Array,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less"></style>
