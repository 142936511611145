const routes = [
  //防疫登记
  //首页
  {
    path: "/futureTreat/epidemicPrevent",
    name: "epidemicPrevent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/index.vue"], resolve),
  },
  //防疫资讯
  {
    path: "/futureTreat/epidemicNews",
    name: "epidemicNews",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicNews.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRegister",
    name: "epidemicRegister",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicRegister.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRes",
    name: "epidemicRes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/epidemicRes.vue"], resolve),
  },
  /* 业主投票 STRART */
  // 首页
  {
    path: "/futureTreat/householdVote",
    name: "householdVote",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/index.vue"], resolve),
  },
  // 议事厅
  {
    path: "/futureTreat/discussionHall",
    name: "discussionHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/householdVote/discussionHall.vue",
      ], resolve),
  },
  // 发起议题
  {
    path: "/futureTreat/addDiscussion",
    name: "addDiscussion",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/addDiscussion.vue"], resolve),
  },
  // 我的议题
  {
    path: "/futureTreat/myDiscussionList",
    name: "myDiscussionList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/householdVote/myDiscussionList.vue",
      ], resolve),
  },
  // 议题发起投票
  {
    path: "/futureTreat/initiateVote",
    name: "initiateVote",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/initiateVote.vue"], resolve),
  },
  // 议事厅
  {
    path: "/futureTreat/voteHall",
    name: "voteHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/voteHall.vue"], resolve),
  },

  // 议事厅 - 议题上会
  {
    path: "/futureTreat/issueMeeting",
    name: "issueMeeting",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/issueMeeting.vue"], resolve),
  },

  // 议事厅 - 业主大会
  {
    path: "/futureTreat/ownersMeeting",
    name: "ownersMeeting",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/ownersMeeting.vue"], resolve),
  },
  // 议事厅 - 业主决策
  {
    path: "/futureTreat/ownerDecision",
    name: "ownerDecision",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/ownerDecision.vue"], resolve),
  },
  // 议事厅 - 投票详情
  {
    path: "/futureTreat/voteDetail",
    name: "voteDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/voteDetail.vue"], resolve),
  },
  // 议事厅 - 参加大会
  {
    path: "/futureTreat/joinHall",
    name: "joinHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/joinHall.vue"], resolve),
  },
  // 议事厅 - 参加大会
  {
    path: "/futureTreat/voteResult",
    name: "voteResult",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/voteResult.vue"], resolve),
  },

  /* 业主投票 END */
];

export default routes;
