import { getHashParam } from "@/utils/utils.js";
import store from "@/store";
import axios from "@/axios";
import router from "../router";
//扩展函数
function containsStr(str, val) {
  return str && val && str.indexOf(val) >= 0;
}
String.prototype.ossResize = function (resizeWidth, resizeHeight) {
  if (!resizeWidth || resizeWidth === 0) {
    resizeWidth = 500;
  }
  if (!resizeHeight) {
    resizeHeight = resizeWidth;
  }

  let path = this;
  if (path.length === 0) {
    return path;
  }

  if (path.indexOf(",") >= 0) {
    path = path.split(",")[0];
  }

  let OSS_RESIZE = "?x-oss-process=image/resize,m_fill,w_";
  if (
    !containsStr(path, "?") &&
    (containsStr(path, "http://") ||
      containsStr(path, "https://") ||
      containsStr(path, "ftp://"))
  ) {
    path += OSS_RESIZE + resizeWidth + ",h_" + resizeHeight;
  }
  console.log(path);
  return path;
};

// 获取地址栏参数
let user;
const whiteList = [
  "decorationTipsApply",
  "activitySquare",
  "venueAppoint",
  "integrateMall",
  "associationApply",
  "communityTalent",
];
let userInfoTemp = sessionStorage.getItem("userInfo");
if (userInfoTemp) {
  user = JSON.parse(userInfoTemp);
  updateStoreData(user);
} else {
  //  判定是否有authCode字段
  const authCode = getHashParam("authCode");
  // 如果页面不是decorationTips &  是 decorationTipsApply 页面,有authCode字段调接口获取信息
  router.beforeEach((to, from, next) => {
    if (whiteList.includes(to.name)) {
      if (authCode) {
        if (!sessionStorage.getItem("userInfo")) {
          getInfo(authCode).then((res) => {
            user = {
              assetId: res.assetId,
              communityId: res.communityId,
              houseId: res.houseId,
              defOrgId: res.orgId,
              tenantId: res.tenantId,
              userId: res.userId,
              openId: res.openId,
            };
            sessionStorage.setItem("userInfo", JSON.stringify(user));
            updateStoreData(user);
          });
        }
      }
      next();
    } else {
      if (!sessionStorage.getItem("userInfo")) {
        let communityId = getHashParam("communityId");
        let userId = getHashParam("userId");
        let houseId = getHashParam("houseId");
        let defOrgId = getHashParam("defOrgId");
        let tenantId = getHashParam("tenantId");
        let openId = getHashParam("openId");
        let assetId = getHashParam("assetId");
        let userInfo = {
          communityId: communityId,
          userId: userId,
          houseId: houseId,
          defOrgId: defOrgId,
          tenantId: tenantId,
          openId: openId,
          assetId: assetId,
        };
        user = userInfo;
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        updateStoreData(user);
      }
      next();
    }
  });
}
// 更新vuex-store数据
function updateStoreData(user) {
  store.commit("setAssetId", user.assetId);
  store.commit("setOpenId", user.openId);
  store.commit("setDefOrgId", user.defOrgId);
  store.commit("setHouseId", user.houseId);
  store.commit("setCommunityId", user.communityId);
  store.commit("setUserId", user.userId);
  store.commit("setTenantId", user.tenantId);
}
// 通过authcode 获取信息
async function getInfo(authCode) {
  const URL = `/gateway/province-future/province/future/login`;
  const appId = "e274adc9708c470993e46b65e8e0ecf0";
  return await axios
    .post(`${URL}?appId=${appId}&code=${authCode}`)
    .then((res) => {
      if (res.code == 200) {
        return res.data;
      }
    });
}
